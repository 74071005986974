<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="版本管理" name="first">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="平台:">
                            <el-select v-model="queryParams.platform" placeholder="请选择平台" clearable @change="changeQuery">
                                <el-option v-for="items in platformList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="当前版本:">
                            <el-input v-model="queryParams.v" placeholder="请输入当前版本" clearable></el-input>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="changeQuery()">搜索</el-button>
                        </div>
                    </el-form>
                    <div class="action">
                        <el-button type="primary" @click="add()">新增</el-button>
                    </div>
                    <!-- 表单内容 -->
                    <el-table :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                        <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                        <el-table-column label="开始时间" prop="ctime" width="160" align="center"></el-table-column>
                        <!-- <el-table-column prop="etime" label="结束时间" width="180" align="center"></el-table-column> -->
                        <el-table-column label="平台" prop="platform_name" align="center" width="120"></el-table-column>
                        <el-table-column label="链接" align="center" prop="url"></el-table-column>
                        <el-table-column label="当前版本" align="center" width="80" prop="v"></el-table-column>
                        <el-table-column label="灰度人员" align="center" width="100" prop="uids"></el-table-column>
                        <el-table-column label="更新时间" align="center" width="160" prop="ctime"></el-table-column>
                        <el-table-column label="限制最低版本" align="center" width="80" prop="small_v"></el-table-column>
                        <el-table-column label="限制最高版本" align="center" width="80" prop="large_v"></el-table-column>
                        <el-table-column prop="content" label="内容" align="center" width="100"></el-table-column>
                        <el-table-column label="状态" align="center" width="80">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">不可用</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">可用</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="200" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑
                                </el-button>
                                <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线</el-button>
                                <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">上线</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />

                    <!-- 新增 编辑 -->
                    <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
                        <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                            <el-form-item label="内容" prop="content">
                                <el-input v-model="form.content" type="textarea" placeholder="请输入内容"></el-input>
                            </el-form-item>
                            <el-form-item label="文件链接" prop="url">
                                <el-input class="lage" v-model="form.url" placeholder="请输入文件链接"></el-input>
                                <el-upload class="avatar-uploader up" action="/i/admin/system/upload/wgt" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="hearder">
                                    <i v-if="form.url" class="el-icon-folder-add avatar"></i>
                                    <i v-else class="el-icon-upload2 avatar"></i>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="当前版本" prop="v">
                                <el-input class="lage" v-model="form.v" placeholder="请输入当前版本"></el-input>
                            </el-form-item>
                            <el-form-item label="最低版本" prop="small_v">
                                <el-input class="lage" v-model="form.small_v" placeholder="请输入最低版本"></el-input>
                            </el-form-item>
                            <el-form-item label="最高版本" prop="large_v">
                                <el-input v-model="form.large_v" placeholder="请输入最高版本"></el-input>
                            </el-form-item>
                            <el-form-item label="灰度人员" prop="uids">
                                <el-input v-model="form.uids" placeholder="请输入灰度人员，请用，分割"></el-input>
                            </el-form-item>
                            <el-form-item label="更新时间" prop="ctime">
                                <el-date-picker v-model="form.ctime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="发布平台" prop="platform">
                                <el-select v-model="form.platform" placeholder="请选择平台" class="handle-select mr10">
                                    <el-option v-for="items in platformList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="状态" prop="state">
                                <el-radio-group v-model="form.state">
                                    <el-radio :label="2">上线</el-radio>
                                    <el-radio :label="1">下线</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="单选框" prop="is_update">
                                <el-radio-group v-model="form.is_update">
                                    <el-radio :label="1">升级包</el-radio>
                                    <el-radio :label="2">整包</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="addVisible = false">取消</el-button>
                            <el-button type="primary" @click="saveData">确定</el-button>
                        </span>
                    </el-dialog>
                    <!-- 删除 -->
                    <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
                        <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="removeVisible = false">取 消</el-button>
                            <el-button type="primary">确 定</el-button>
                        </span>
                    </el-dialog>
                    <!-- 上线、下线提示框 -->
                    <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                        <div class="del-dialog-cnt">{{ confirmContent }}</div>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="confirmVisible = false">取 消</el-button>
                            <el-button type="primary" @click="stateData">确 定</el-button>
                        </span>
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane label="用户版本" name="second">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="版本号:">
                            <el-input class="s-input" v-model="queryUserParams.v" placeholder="请输入版本号" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="用户id:">
                            <el-input class="s-input" v-model="queryUserParams.uid" placeholder="请输入用户id" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="平台:">
                            <el-select v-model="queryUserParams.platform" placeholder="请选择平台" clearable @change="changeUserQuery">
                                <el-option v-for="items in platformList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态:">
                            <el-select v-model="queryUserParams.state" placeholder="请选择状态" clearable @change="changeUserQuery">
                                <el-option label="上线" :value="2"></el-option>
                                <el-option label="下线" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="loadUserData()">搜索</el-button>
                        </div>
                    </el-form>
                    <!-- 表格内容 -->
                    <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="userInfo.list" class="el-table">
                        <el-table-column label="id" prop="id" align="center" width="100"></el-table-column>
                        <el-table-column label="用户id" prop="uid" align="center" width="100"></el-table-column>
                        <el-table-column label="设备号" prop="device_id" align="center"></el-table-column>
                        <el-table-column label="设备名字" prop="model" align="center"></el-table-column>
                        <el-table-column label="版本" prop="v" align="center" width="120"></el-table-column>
                        <el-table-column label="平台" prop="platform_name" align="center" width="200"></el-table-column>
                        <el-table-column label="更新时间" prop="utime" align="center" width="200"></el-table-column>
                        <el-table-column label="状态" prop="state" align="center" width="200">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pagination v-bind:pageInfo="userInfo" v-on:refreshPageSize="refreshUserPageSize" v-on:refreshPageNum="refreshUserPageNum" />
                </el-tab-pane>

                <el-tab-pane label="最低版本配置" name="3">
                    <el-form class="form-box" ref="versionForm" v-bind:rules="rules" v-bind:model="versionForm" label-width="100px" style="margin-top:30px">
                        <el-form-item label="desc" prop="desc">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="versionForm.desc" placeholder="请输入desc"></el-input>
                        </el-form-item>
                        <el-form-item label="v" prop="v">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="versionForm.v" placeholder="请输入版本"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveversionForm" class="tijiao">提 交</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
//import { Loading } from 'element-ui';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    // name: 'seller',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            activeName: 'first',
            options: [{
                value: '2',
                label: '可用'
            }, {
                value: '0',
                label: '不可用'
            },],
            menuList: ['设置', '版本管理'],
            pageInfo: {},
            confirmContent: '',
            versionForm: {},
            addVisible: true,
            confirmVisible: false,
            removeVisible: false,
            value: '',
            addVisible: false,
            form: {},
            queryParams: {
                page: 1,
                pagesize: 10
            },
            header: {},
            // 验证
            rules: {
                content: [{
                    required: true,
                    message: '内容不能为空',
                    trigger: 'blur'
                }],
                url: [
                    { validator: deal.checkUrl, trigger: ['blur', 'change'], required: true, }
                ],
                platform: [{
                    required: true,
                    message: '平台不能为空',
                    trigger: 'blur'
                }],
                ctime: [{
                    required: true,
                    message: '起止时间不能为空',
                    trigger: 'blur'
                }],
                v: [{
                    required: true,
                    message: '当前版本不能为空',
                    trigger: 'blur'
                }],
                large_v: [{
                    required: true,
                    message: '最高版本不能为空',
                    trigger: 'blur'
                }],
                small_v: [{
                    required: true,
                    message: '最低版本不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                is_update: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],

            },
            hearder: {},
            title: '加载中',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            platformList: [],
            positionList: [],
            queryUserParams: {
                name: '',
                page: 1,
                pagesize: 10,
            },
            userInfo: {},
        }

    },

    created() {
        this.hearder.token = localStorage.getItem('token');
        this.hearder.platform = 'admin';
        deal.getconf('', this)
        this.loadData()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            if (tab.paneName === "first") {
                this.loadData()
            } else {
                this.loadUserData()

            }
        },
        //平台
        statestam(row) {
            return deal.platformFormat(row.platform);
        },

        // 版本列表
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/system/version/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        if (_this.platformList) {
                            _this.platformList.map((item) => {
                                if (item.key === val.platform) {
                                    _this.$set(val, 'platform_name', item.name)
                                }
                            })
                        }
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 最低版本配置
        loadversionForm() {
            this.loading = true;
            request.get('/system/version/limit/find').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.versionForm = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 最低版本编辑
        saveversionForm() {
            this.loading = true;
            request.post('/system/version/limit/edit', this.versionForm).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.versionForm = ret.data;
                    this.$message.success('编辑成功');
                    this.loadversionForm()
                    this.$refs.versionForm.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //新增
        add() {
            this.mode = 'add';
            this.title = '新增版本管理';
            this.form = {
                state: 2,
                is_update: 1,
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        // 上传文件
        beforeAvatarUpload(file) {
            loads.open('.avatar-uploader .el-upload', '上传中')
            let fileName = file.name
            let pos = fileName.lastIndexOf('.')
            let lastName = fileName.substring(pos, fileName.length)
            console.log(lastName.toLowerCase());
            if (lastName.toLowerCase() !== '.wgt' && lastName.toLowerCase() !== '.ipa' && lastName.toLowerCase() !== '.apk') {
                this.$message.error('不支持此文件格式!');
            }
            // Loading.service({ text: '文件上传中' });

        },
        // 上传图片
        handleAvatarSuccess(res, file) {
            // Loading.service().close();
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'url', res.data.uri);
            }
        },
        // 编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑版本管理';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'ctime', item.ctime);
            console.log(77777, this.form)
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            console.log(333, this.form)
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/system/version/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 新增编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/system/version/add' : '/system/version/edit';
                        var param = Object.assign({}, this.form);
                        param.ctime = deal.timestamp(this.form.ctime);
                        //处理时间
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        /// 用户版本数据

        cellstyle() {
            return 'text-align: center';
        },
        //获取用户版本列表
        loadUserData() {
            this.loading = true;
            let _this = this
            request.get('/system/version/user/list', this.queryUserParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.userInfo = ret.data;
                    this.userInfo.list = ret.data.list
                    this.userInfo.list.map(function (val) {
                        if (_this.platformList) {
                            _this.platformList.map((item) => {
                                if (item.key === val.platform) {
                                    _this.$set(val, 'platform_name', item.name)
                                }
                            })
                        }
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeUserQuery() {
            this.queryUserParams.page = 1;
            this.loadUserData();
        },
        refreshUserPageSize(val) {
            this.queryUserParams.pagesize = val;
            this.loadUserData();
        },
        refreshUserPageNum(val) {
            this.queryUserParams.page = val;
            this.loadUserData();
        }
    },


}
</script>
<style  scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.form-input >>> .el-input__inner {
    width: 900px;
    border-radius: 4px;
}
.tijiao {
    margin-top: 60px;
}
</style>